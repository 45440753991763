

.context-chatbot-chatbot {
    text-align: center; /* Center align text */
    margin-bottom: 30px; /* Add spacing below the heading */
}

.context-chatbot-chatbot h1 {
    font-size: 48px; /* Adjust heading font size */
    color: #333; /* Set heading color */
    text-transform: uppercase; /* Transform text to uppercase */
    letter-spacing: 2px; /* Add letter spacing */
    text-align: center;
}

.area-chatbot {
    position: relative; /* Set position relative for absolute positioning */
    overflow: hidden; /* Hide overflowing content */
}

.background-img {
  
    width: 100%; /* Full width of .area-chatbot */
    height: 100%; /* Full height of .area-chatbot */
    z-index: 0; /* Send image behind other content */
    opacity: 0.8; /* Adjust opacity if needed */
    /* margin-top: 80px; */
    margin-top: 65px;
}

.backgroundImg {
    display: block;
    /* width: 100%;
    height: auto; */
    width: 100%;
    opacity: 10%;
}

.circles {
    list-style: none; /* Remove default list styles */
    margin: 0;
    padding: 0;
    position: absolute; /* Position circles absolutely */
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%); /* Center align horizontally */
    z-index: 1; /* Bring circles in front of background */
}

.circles li {
    width: 15px;
    height: 15px;
    background-color: #fff; /* Circle background color */
    border-radius: 50%; /* Make circles round */
    margin: 0 5px; /* Adjust margin between circles */
    display: inline-block; /* Display circles inline */
}

/* Text Animation */
.context-chatbot h1 {
    position: relative;
    overflow: hidden;
}

.context-chatbot h1::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #333; /* Line color */
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.5s ease;
}

.context-chatbot h1:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}
.context-chatbot {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
  transform: translate(-50%, 50%);
    z-index: 2;
    color: #fff;
    overflow: hidden;
    white-space: nowrap;
    /* animation: typing 6s steps(30, end); */
}
.circles {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
    opacity: 10%;
}
