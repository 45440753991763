
body{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #f0f0f0;
  font-family: poppins, robot;
}
.main-header:hover {
  background: #cc6016;
}
.main-header a.nav-link:hover {
  /* color: #000 !important; */
}
/* ----custom scroolbar---- */
::-webkit-scrollbar{
  width: 10px;
}
::-webkit-scrollbar-track{
  background-color: #fff;
}
::-webkit-scrollbar-thumb{
  background-color: #d59116;
  border-radius: 20px;
}


.main-bar{
  background: #000;
}
/* start--header-css- */

.main-header{
  background: #131313;
    position: fixed;
    z-index: 999;
}
.header-bar {
  align-items: center;
  font-family: poppins , robots, sysyem-ui;
  font-size: 14px;
  

  a.nav-link.active {}

  a.nav-link {
    letter-spacing: 0.3px;
    color: #fff;
  }
  a.nav-link.active {
    background: inherit !important;
  }
  a.nav-link:hover {
    letter-spacing: 0.3px;
    color: #d59116;;
    font-weight: 500;
    transition: 0.3s;
    /* text-decoration: underline; */
  }
  .project-logo img {
    width: 160px;
  }
  ul.nav.nav-pills {
    gap: 20px;
}
}


.dropdown {
  background: inherit !important;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  font-family: poppins, robot;
}

a.dropdown-setup {
  color: #fff;
  text-decoration: none;
}
a.dropdown-setup:hover {
  color: #000;
  font-weight: 500;
  transition: 0.3s;
}

ul.dropdown-menu.show li a.dropdown-item {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
ul.dropdown-menu.show {
  background: #f5f5fb;
  border: none;
}
ul.dropdown-menu.show li a.dropdown-item:active {
  background: #000;
}
a.dropdown-setup.dropdown-toggle::after {
  transform: rotate(0deg);
  transition: 0.3s;
}
a.dropdown-setup.dropdown-toggle.show::after {
  transform: rotate(180deg);
  transition: 0.3s;
}
/* @media (max-width: 992px) {
  .header-bar {
    flex-wrap: wrap;
  }

  .nav-pills {
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .nav-item {
    margin-right: 0 !important; 
    margin-bottom: 5px;
  }

  .btn-start {
    margin-top: 10px; 
  }
} */
/* start--header-css- */


/* start-Home-css */

.home-page{
  background: #f0f0f0;
  padding: 50px 0px;
}
.bright-background{
  padding: 60px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
  .inline-btn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}
.inline-btn button {
  background: none;
  color: #fff;
  border: 1px solid #d59116;
  padding: 5px 15px;
  list-style: none;
  text-decoration: none;
  border-radius: 4px;
  letter-spacing: 0.3px;
  transition: 0.3s;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.inline-btn button:hover {
  background: #d59116;
  transition: 0.6s;
}
.bright-website {
  color: #d59116;
  text-decoration: none;
}


.Bright-text {
    font-family: poppins,robot;
    color: #fff;
    letter-spacing: 0.3px;
    margin: 20px 0px 30px 0px;
}

 .Bright-text h5 {
   font-size: 46px;
    margin-bottom: 0px;
    font-weight: 600;
    letter-spacing: 0.3px;
    /* text-align: justify; */
}

.Bright-text p {
    font-size: 15px;
    padding-left: 6px;
    color: #bab9b9;
    margin: 5px 0px;
    text-align: justify;
}
.bright-text {
  margin: 25px 0px 0px 0px;
  font-family: poppins, robot;
  letter-spacing: 0.3px;
}
.bright-text h5 {
  text-align: center;;
    font-size: 23px;
    font-weight: 600;
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    opacity: 85%;
}
.bright-text h5::before {
  content: "";
  width: 40px;
  height: 2px;
  background: #d59116;
  display: inline-block;
  margin-right: 5px;
}

.bright-text h5::after {
  content: "";
  width: 40px;
  height: 2px;
  background: #d59116;
  display: inline-block;
  margin-left: 5px;
}

.bright-text p {
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  color: #454444;
  margin-bottom: 40px;
}
.inlines-btn {
  margin-bottom: 12px;
}
.inlines-btn button {
  background: none;
  color: #000;
  border: 1px solid #d59116;
  padding: 5px 15px;
  list-style: none;
  text-decoration: none;
  border-radius: 4px;
  letter-spacing: 0.3px;
  transition: 0.3s;
  font-size: 14px;
  font-family: poppins, robot;
  width: 110px;
}
.inlines-btn button:hover {
  background: #d59116;
  color: #fff;
  transition: 0.6s;
}

.About-title {
  font-family: poppins , robot;
  letter-spacing: 0.3px;
  text-align: justify;
}

.About-title p {
  font-size: 14px;
  text-align: justify;
}
/* .about-img{
  height: 250px;
} */
.about-img{
  width: 100%;
  height: 250px;
  background-image: url('https://cc-prod.scene7.com/is/image/CCProdAuthor/product-photography_P3B_720x350?$pjpeg$&jpegSize=200&wid=720');
  background-size: 100% 100%;
  animation: changeImage 10s linear infinite;
  border-radius: 4px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px
}
@keyframes changeImage{
  0%{
    background-image: url('https://cc-prod.scene7.com/is/image/CCProdAuthor/product-photography_P3B_720x350?$pjpeg$&jpegSize=200&wid=720');
  }
  25%{
    background-image: url('https://cc-prod.scene7.com/is/image/CCProdAuthor/product-photography_P3B_720x350?$pjpeg$&jpegSize=200&wid=720');
  }
  50%{
    background-image: url('https://community.nasscom.in/sites/default/files/media/images/ott-web.jpg');
  }
  75%{
    background-image: url('https://cc-prod.scene7.com/is/image/CCProdAuthor/product-photography_P3B_720x350?$pjpeg$&jpegSize=200&wid=720');
  }
  100%{
    background-image: url('https://community.nasscom.in/sites/default/files/media/images/ott-web.jpg');
  }
}

 .pricing-item {
  position: relative;
  z-index: 2;
  background-color: #fff;
  border: 1px solid #d5cfcf;
  box-shadow: 0 3px 20px -2px color-mix( #ff8e00eb, transparent 90%);
  border-top: 4px solid #fda60a;
  padding: 40px;
  height: 100%;
  border-radius: 5px;
  transition: 0.3s;
}
.pricing-item::after{
  position: absolute;
    content: "";
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    bottom: auto;
    background: #fda60a;
    border-radius: 8px;
    transition: 1s;
}
.pricing-item h4 {
  font-size: 22px;
  font-family: poppins, robot;
  letter-spacing: 0.3px;
  margin-bottom: 5px;
  font-weight: 500;
  color: #d59116;
}
.pricing-item h3 {
  font-size: 25px;
  font-family: poppins, robot;
  font-weight: 600;
  letter-spacing: 0.3px;
  opacity: 90%;
}
.pricing-item ul {
  list-style: none;
  padding: 0;
}
.pricing-item ul li span {
  font-size: 14px;
  font-family: poppins, robot;
  letter-spacing: 0.3px;
}
.pricing-item h4 sup {
  color: #ff8e00eb;
}

.pricing-item h4 span {
  color: #3a3838;
}
.pricing-item a.buy-btn {
  font-family: poppins, robot;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
}
.pricing-item:hover {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  transition: 0.6s;
}
/* end-Home-css */



/* start-pricing-section */

.pricing{
  font-family: poppins, robot;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px;
}

h2 {
  margin: 0;
}

/* Body */
/* Card Themes - Start */
.basic .pricing-header {
  background-color: #000;
}
.basic .price-circle {
  border: 10px solid #000;
  transition: all 0.4s;
}
.basic:hover .price-circle {
  border-width: 5px;
}
.basic .buy-now:hover {
  background-image: none !important;
  background-color: #000 !important;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
}
.pro .pricing-header {
  background-color: #26de81;
}
.pro .price-circle {
  border: 10px solid #26de81;
  transition: all 0.4s;
}
.pro:hover .price-circle {
  border-width: 5px;
}
.pro .buy-now:hover {
  background-image: none !important;
  background-color: #26de81 !important;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
}

.business .pricing-header {
  background-color: #a55eea;
}
.business .price-circle {
  border: 10px solid #a55eea;
  transition: all 0.4s;
}
.business:hover .price-circle {
  border-width: 5px;
}
.business .buy-now:hover {
  background-image: none !important;
  background-color: #a55eea !important;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
}
.column {
  /* flex: 25%; */
  padding: 10px;
  /* width: calc(33.3% - 30px); */
  box-sizing: border-box;
  text-align: center;
  font-family: poppins, robot;
}
/* @media screen and (max-width: 980px) { */
  /* .column {
    flex: 100%;
    display: block;
  } */
/* } */
/* @media screen and (max-width: 700px) { */
  /* .column {
    flex: 100%;
    display: block;
  } */
/* } */

.pricing-card {
  -webkit-transition: 0.4s background-color ease;
  -ms-transition: 0.4s background-color ease;
  transition: 0.4s background-color ease;
  /* height: 510px; */
  background-color: white;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 8px;
  position: relative;
  transition: all 0.4s;
}
.pricing-card:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  transform: scale(1.05);
}
.pricing-card .popular {
  position: absolute;
  top: 0;
  right: 5%;
  width: auto;
  padding: 10px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #eb3b5a;
  color: white;
  font-size: 12px;
  z-index: 1;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.pricing-card .badge-box {
  padding: 0 40px;
  margin-top: 80px;
  text-align: center;
}
.pricing-card .badge-box span {
  display: inline-block;
  border: 1px solid #d59116;
  padding: 4px 12px;
  border-radius: 25px;
  overflow: hidden;
  color: #d59116;
}
.pricing-card .pricing-header {
  width: 100%;
  height: 150px;
  position: relative;
  border-radius: 20px 20px 0 0;
  -webkit-border-radius: 8px 8px 0 0;
  -moz-border-radius: 20px 20px 0 0;
}
.pricing-card .pricing-header .plan-title {
  font-size: 24px;
  color: white;
  position: relative;
  top: 25%;
  font-family: poppins, robot;
  font-weight: 500;
}
.pricing-card .pricing-header .price-circle {
  width: calc(33.3% - 30px);
  width: 120px;
  height: 120px;
  border-radius: 100%;
  left: calc(50% - 60px);
  top: 60%;
  background-color: white;
  position: absolute;
}
.pricing-card .pricing-header .price-circle .info {
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: gray;
}
.pricing-card .pricing-header .price-circle .price-title {
  display: block;
  font-size: 28px;
  padding: 28px 0 0;
  font-weight: bold;
}
.pricing-card .pricing-header .price-circle .price-title small {
  font-size: 18px;
}
.pricing-card .pricing-header h2 {
  position: relative;
  top: 40%;
  color: #fff;
}
.pricing-card ul {
  margin: 10px 0 0 0;
  padding: 0;
  text-align: center;
}
.pricing-card ul li {
  list-style-type: none;
  display: block;
  padding: 15px 0 15px 0;
  margin: 0;
  border-bottom: 1px solid #f2f2f2;
}
.pricing-card .buy-button-box {
  width: 100%;
  float: left;
  margin-top: 50px;
  text-align: center;
}
.pricing-card .buy-button-box .buy-now {
  text-decoration: none;
  color: white;
  padding: 10px 30px;
  border-radius: 20px;
  background-image: linear-gradient(to left, #a55eea, #45aaf2);
  margin-top: 20px;
}
/* end-pricing-section */

/* start-our-products-section */
* {
  margin: 0;
  padding: 0;
}

.our-products {
  position: relative;
  width: 1160px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  transform-style: preserve-3d;
  perspective: 500px;
  margin: auto;
}
.our-products .box {
  position: relative;
  width: 240px;
  height: 275px;
  background: #000;
  transition: 0.5s;
  transform-style: preserve-3d;
  overflow: hidden;
  margin-right: 15px;
  margin-top: 45px;
}
.our-products:hover .box {
  transform: rotateY(25deg);
}
.our-products .box:hover ~ .box {
  transform: rotateY(-25deg);
}
.our-products .box:hover {
  transform: rotateY(0deg) scale(1.25);
  z-index: 1;
  box-shadow: 0 25px 40px rgba(0,0,0,0.5);
}
.our-products .box .imgBx {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.our-products .box .imgBx:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg,#f00,#000);
  z-index: 1;
  opacity: 0;
  transition: 0.5s;
  mix-blend-mode: multiply;
}
.our-products .box:hover .imgBx:before {
  opacity: 1;
}
.our-products .box .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.our-products .box .content {
  position: absolute;
  top: 30px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  padding: 20px;
  align-items: flex-end;
  box-sizing: border-box;
  justify-content: end;
}
.our-products .box .content h2 {
  color: #fff;
  transition: 0.5s;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 20px;
  transform: translateY(200px);
  transition-delay: 0.3s;
}
.our-products .box:hover .content h2 {
  transform: translateY(0px);
}
.our-products .box .content p {
  color: #fff;
  transition: 0.5s;
  font-size: 14px;
  transform: translateY(200px);
  transition-delay: 0.4s;
}
.our-products .box:hover .content p {
  transform: translateY(0px);
}
/* about-css */

.stats {
  text-align: center;
}

.stats-item {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.stats-item:hover {
  transform: translateY(-10px);
}

.stats-item span {
  font-size: 35px;
  color: #000;
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
}    
.stats-item span .icon{
  opacity: 90%;
  color: #d59116;
} 

.stats-text {
  font-size: 1.2rem;
  color: #666;
}

@media (max-width: 768px) {
  .stats-item {
      padding: 20px;
  }
}
.Bright-text h5 {
  /* font-size: 3em; */
  color: #fff;
  /* overflow: hidden; 
  word-break: break-all;  */
  animation: typing 2s steps(30, end); /* Animation duration and steps */
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.slide-up {
  opacity: 0;
  transform: translateY(20px);
  animation: slideUpAnimation 1s ease-in-out forwards;
}

@keyframes slideUpAnimation {
  from {
      opacity: 0;
      transform: translateY(20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}


/* ecommanrce-css */

.img-list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 65px;
  }
  .img-list li {
  border: 5px solid transparent;
  box-sizing: border-box;
  width: 33.33%;
  float: left;
  position: relative;
  cursor: pointer;
  }
  .img-list img {
  max-width: 100%;
  vertical-align: middle;
  }
  
  /* Image Placeholder Styles
  -------------------------------------------------------------- */
  .img-placeholder:before,
  .img-placeholder:after {
  padding-top: 33.33%;
  content: "";
  display: block;
  }  
  .img-placeholder {
  background: #222;
  box-shadow: 0 0 0 8px #222 inset, 0 0 0 9px #333 inset;
  color: #444;
  line-height: 0;
  text-align: center;
  display: block;
  }
  
  /* The hover effect
  -------------------------------------------------------------- */
  .img-list li:before {
  transition: all .5s ease;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #333;
  transform: scale(0);
  }
  .img-list li:hover:before {
  opacity: .5;
  transform: scale(1);
  }
  .img-list li:after {
  transition: all .6s ease .2s;
  content: "";
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  border: 1px solid #aaa;
  background: #000;
  opacity: 0;
  transform: scale(0);
  }
  .img-list li:hover:after {
  opacity: .35;
  transform: scale(1);
  }
  .rounded-img {
    border-radius: 221px 71px 186px 70px;
    opacity: 25% !important;
}



/* header */

/* For toggler icon color */
.navbar-toggler-icon {
  background-color: white; /* Example */
}

/* Adjust margins for the dropdown menu */
.dropdown-menu {
  margin: 0; /* Example */
}
ul.navbar-nav.ms-auto{
  align-items: center;
}

/* Example of making the navbar responsive */
@media (min-width: 1040px) and (max-width: 991.98){
  ul.navbar-nav.ms-auto {
    width: 60% !important;
    display: flex;
    justify-content: space-between;
    margin: 0px !important;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    justify-content: end;
}
}

@media (max-width: 991.98px) {
  .navbar-nav {
      flex-direction: column; /* Stack items vertically */
      padding-left: 0; /* Reset padding */
  }
  .nav-item {
      width: 100%; /* Full width for each item */
  }
  .dropdown-menu {
      position: static;
      float: none;
  }
  .dropdown{
    display: block !important;
    font-size: 15px;
    font-weight: 500;
  }
  ul.dropdown-menu.show {
    width: 50px;
    font-size: 14px;
    letter-spacing: 0.3px;
}
ul.navbar-nav.ms-auto {
  width: 0px !important;
  display: inherit !important;
  justify-content: inherit !important;
  margin: 0px !important;
}
 .btn-set{
  margin: 10px 0px 15px 0px;
 }
 .stats-item{
  margin: 10px 0px;
 }
}
a.nav-link.dropdown-toggle.show:after {
  transform: rotate(180deg);
  transition: 0.3s;
}
a.nav-link.dropdown-toggle:after{
  transform: rotate(0deg);
  transition: 0.3s;
}
.project-logo img {
  width: 185px;
}
.navbar-dark .navbar-toggler {
  border: 1px solid #fff !important;
}
.navbar-nav .nav-link {
  padding-left: 10px !important;
}
.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}
ul.dropdown-menu.show li a.dropdown-item {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
}
.main-header a.nav-link:hover {
  /* color: #000 !important; */
  font-weight: 500;
  /* letter-spacing: 0.3px; */
}
a.nav-link {
  padding: 5px 35px !important;
}
a.nav-link {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #fff;
}
button.btn-start.btn-set{
  background: none;
  color: #fff;
  border: 1px solid #d59116;
  padding: 7px 10px;
  width: 142px;
  list-style: none;
  text-decoration: none;
  border-radius: 4px;
  letter-spacing: 0.3px;
}
button.btn-start.btn-set:hover {
  background: #d59116;
}
button.btn-start.btn-set a {
color : #fff;
}
/* header---end */


/* login-page start */

.login-page{
  width: 400px;
}
.form {
  background-color: #15172b;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 40px;
}

.title.login {
  color: #eee;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-right: 0px;
}

.subtitle {
  color: #eee;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0px;
}

.input-container {
  height: 50px;
  position: relative;
  width: 100%;
}

.ic1 {
  margin-top: 40px;
}

.ic2 {
  margin-top: 30px;
}

.input {
  background-color: #303245;
  border-radius: 5px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  font-size: 18px;
  height: 100%;
  outline: 0;
  padding: 4px 20px 0;
  width: 100%;
}

.cut {
  background-color: #15172b;
  border-radius: 10px;
  height: 20px;
  left: 28px;
  position: absolute;
  top: -20px;
  transform: translateY(0);
  transition: transform 200ms;
  width: 76px;
}

.cut-short {
  width: 50px;
}

.input:focus ~ .cut,
.input:not(:placeholder-shown) ~ .cut {
  transform: translateY(8px);
}

.placeholder {
  color: #65657b;
  font-family: sans-serif;
  left: 20px;
  line-height: 14px;
  pointer-events: none;
  position: absolute;
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms;
  top: 20px;
}

.input:focus ~ .placeholder,
.input:not(:placeholder-shown) ~ .placeholder {
  transform: translateY(-30px) translateX(10px) scale(0.75);
}

.input:not(:placeholder-shown) ~ .placeholder {
  color: #808097;
}

.input:focus ~ .placeholder {
  color: #dc2f55;
}

.submit {
  background-color: #bd8200;
  border-radius: 5px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  cursor: pointer;
  font-size: 18px;
  height: 50px;
  margin-top: 38px;
  font-weight: 500;
  text-align: center;
  width: 100%;
}

.submit:active {
  background-color: #ad7806;
}
.account-page{
  padding: 100px 0px 50px 0px;
}
.login-page{
  padding: 100px 0px 50px 0px;
}
/* login-page end */

/* choose-file start css */

.content-choose h5{
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.3px;
  margin-bottom: 2px;
  color: #d59116;
}
.content-choose p {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0px;
  letter-spacing: 0.3px;
  opacity: 90%;
  text-align: justify;
}
.choosing-card{
transition: 0.6s;
border-radius: 8px;
/* border-bottom: 2px solid black; */
}
.choosing-card {
  position: relative;
  border-radius: 8px;
  transition: 0.6s;
  overflow: hidden; /* To ensure the line does not overflow the card */
}

.choosing-card::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #cc6016;
  transform: translateX(-100%);
  transition: transform 0.6s ease;
}

.choosing-card:hover::after {
  transform: translateX(0);
}

/* Optionally, if you want to add an additional effect to ensure smoothness */
.choosing-card::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 4px;
  background-color: #cc6016;
  transition: width 0.6s ease;
}

.choosing-card:hover::before {
  width: 100%;
}

.choosing-card:hover{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
transition: 0.6s;
}
.icon-col svg {
  width: 75px;
  height: 75px;
  opacity: 50%;
}
.icon-col svg:hover {
  fill: #1c8cd6;
}
/* choose-file end css */
@media (max-width: 1198px) {
.Bright-text h5 {
  font-size: 39px !important;
}
}

/* start-swiper-overflow-setup */
.our-product-mange .slick-slider.slick-initialized button.slick-arrow.slick-next {
  right: -12px !important;
}
/* end-swiper-overflow-setup */