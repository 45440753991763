/* ecommanrce-css */
body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
a {
    text-decoration: none;
    color: #adadad;
  }
  .btn {
    display: inline-block;
    padding: .5rem 1rem;
    background-color: #cd8c38;
    border-radius: 2rem;
    color: #1e1f26;
    transition: .3s;
  }
  .btn:hover {
    background-color: #92601f;
  }
  .btn-l {
    width: 100%;
    text-align: center;
  }
  body {
    background-color: #1e1f26;
  }
  .top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 80px 0px 0px 12px;
  }
  .left-content,
  .right-content {
    display: flex;
    align-items: center;
  }
  .title {
    margin-right: 165px;
    color: #fff;
    font-size: 27px;
    margin-bottom: 13px;
  }
  .navigation {
    display: flex;
    list-style: none;
  }
  .navigation li {
    margin-right: 1rem;
  }
  .navigation li a:hover {
    color: #cd8c38;
  }
  .active {
    color: #cd8c38;
  }
  .filter,
  .cart,
  .help,
  .menu {
    position: relative;
    width: 20px;
    height: 20px;
  }
  .filter,
  .cart,
  .help {
    margin-right: 1rem;
  }
  .menu {
    margin-left: .8rem;
  }
  .filter,
  .menu {
    display: none;
  }
  .profile-img-box {
    position: relative;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    overflow: hidden;
  }
  .upcoming-movie{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 50%;
  }
  .main-container {
    color: #adadad;
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 2rem;
  }
  .sidebar {
    background: linear-gradient(45deg, rgba(255, 255, 255, .05), rgba(205, 140, 56, .15));
    backdrop-filter: blur(5px);
    height: fit-content;
    border-radius: 1rem;
    padding: 25px 25px 10px 25px;
  }
  .sidebar-groups {
    margin-bottom: 1rem;
  }
  .sg-title {
    margin-bottom: .5rem;
    color: #fff;
    font-size: 20px;
  }
  input {
    margin-bottom: 9.8px;
  }
  input[type=checkbox]:checked {
    accent-color: #cd8c38;
  }
  input[type=radio]:checked {
    accent-color: #cd8c38;
  }
  label {
    margin-left: .5rem;
  }
  /* movies container */
  .upcoming-img-box {
    position: relative;
    height: 300px;
    width: 100%;
    border-radius: 1rem;
    overflow: hidden;
    margin-bottom: 1.5rem;
  }
  .upcoming-title {
    position: absolute;
    top: 2rem;
    left: 2rem;
    color: #cd8c38;
    background-color: #1e1f26;
    padding: .5rem 1rem;
    border-radius: 2rem;
    border: 1px solid #cd8c38;
    font-weight: 500;
  }
  .buttons {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
  }
  .btn-alt {
    background-color: #1e1f26;
    color: #cd8c38;
    border: 1px solid #cd8c38;
    margin-left: 1rem;
  }
  .btn-alt:hover {
    color: #1e1f26;
    background-color: #cd8c38;
  }
  /* current movies */
  .movies-container {
    padding-bottom: 2rem;
  }
  .current-movies {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
  }
  .current-movie {
    background: linear-gradient(45deg, rgba(255, 255, 255, .05), rgba(205, 140, 56, .15));
    padding: 0;
    border-radius: .5rem;
  }
  .cm-img-box {
    position: relative;
    height: 150px;
    width: auto;
    margin-bottom: 1rem;
    border-radius: .5rem;
    overflow: hidden;
  }
  .movie-title {
    color: #fff;
    margin-bottom: .2rem;
    font-size: 20px;
  }
  .booking {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
  }
  .price {
    color: #cd8c38;
    font-size: 28px;
  }
  .movie-imgs{
    width: 250px;
    border-radius: 8px 8px 0px 0px;
  }
  .moive-content {
    padding: 2px 14px 25px 14px;
}