/* Responsive CSS */
@media (max-width: 991.98px) { /* Tablets and smaller desktops */
    .navbar-collapse {
      justify-content: center; /* Center align items in the collapsed navbar */
      
    }
  }
  
  @media (max-width: 767.98px) { /* Mobile devices */
    .navbar-collapse {
      flex-direction: column; /* Stack items vertically in collapsed mode */
    }
    .dropdown-menu {
      position: static; /* Override Bootstrap dropdown menu position for mobile */
      top: auto;
      left: auto;
      transform: none;
      width: auto;
      margin: 0;
      float: none;
    }
  }
  a.nav-link {
    color: #fff !important;
}
.custom-toggler .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em; /* Adjust size as needed */
    height: 1.5em; /* Adjust size as needed */
    background-image: url('https://e7.pngegg.com/pngimages/250/597/png-clipart-white-stripes-illustration-white-menu-icon-icons-logos-emojis-menu-icons.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  
  