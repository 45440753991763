
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
  }
  body{
    font-family: poppins, robot;
  }
  .footer-main{
    background: #000 !important;
  }
  
  .footers a {color:#c1bdbd;}
.footers p {color:#696969;}
.footers ul {line-height:30px;}
#social-fb:hover {
     color: #3B5998;
     transition:all .001s;
 }
 #social-tw:hover {
     color: #4099FF;
     transition:all .001s;
 }
 #social-gp:hover {
     color: #d34836;
     transition:all .001s;
 }
 #social-em:hover {
     color: #f39c12;
     transition:all .001s;
 }
 .text-white10{
  font-size: 16px;
  letter-spacing: .3px;
  color: #fff;
 }
 .list-unstyled li {
  font-size: 14px;
  letter-spacing: 0.3px;
}
.para{
  font-size: 14px;
  letter-spacing: 0.3px;
  color: #c1bdbd !important;
  text-align: justify;
}
.social-icons svg{
  fill: #fff;
  width: 25px;
  height: 25px;
}
.cybersolvings:hover{
color: #fff;
text-decoration: underline;
}

 