.slick-slider.slick-initialized button.slick-arrow.slick-prev:before {
    color: #ff8e00eb !important;
    font-size: 25px !important;
 }
 .slick-slider.slick-initialized button.slick-arrow.slick-next:before{
     color: #ff8e00eb !important;
     font-size: 25px !important;
 }
 .bright-card{
     box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
 }
 .img-bright {
     display: flex;
     justify-content: center;
     position: relative;
     overflow: hidden;
 }
 .card-body {
     font-family: Poppins ,robot;
     letter-spacing: 0.3px;
     text-align: center;
     padding: 10px 30px 35px 30px;
 }
 .content{
     position: relative;
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
     transition: 0.5s;
     padding: 30px 0px 15px 0px;
 }
 .content:hover{
     transform: translateY(-20px);
 }
 
 .card-body h5 {
     font-size: 19px;
     font-weight: 500;
     color: #faac4a;
     text-align: center;
 }
 
 .card-body p {
     font-size: 13px;
     margin-bottom: 25px;
 }
 .btn-setup {
     text-align: center;
 }
 a.more-btn {
     text-decoration: none;
     text-transform: uppercase;
     font-size: 15px;
     font-family: poppins, robot;
     font-weight: 500;
     letter-spacing: 0.3px;
     border: 1px solid #faac4a;
     border-radius: 4px;
     padding: 4px 25px;
     color: black;
     transition: 0.6s;
 }
 a.more-btn:hover {
     color: #fff;
     background-color: #000;
     border: none;
     transition: 0.3s;
     font-weight: 400;
 }
 img.communication {
     width: 252px;
     height: 175px;
 }
 .price-circle {
    width: 100px; /* Adjust dimensions as needed */
    height: 100px;
    background-image: url('https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg');
    background-size: cover; /* Ensures the image covers the entire area */
    background-position: center; /* Centers the image */
    border-radius: 50%; /* Makes the div circular */
}
.pricing-card .pricing-header .price-circles {
    width: calc(33.3% - 30px);
    width: 120px;
    height: 120px;
    border-radius: 100%;
    left: calc(50% - 60px);
    top: 60%;
    background-color: white;
    position: absolute;
}
.basic .price-circles {
    border: 10px solid #000;
    transition: all 0.4s;
}
.basic:hover .price-circles {
    border-width: 5px;
  }
  .basic .buy-now:hover {
    background-image: none !important;
    background-color: #000 !important;
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
  }
.price-circles {
    width: 100px; /* Adjust dimensions as needed */
    height: 100px;
    background-image: url('https://images.pexels.com/photos/3756681/pexels-photo-3756681.jpeg?cs=srgb&dl=pexels-olly-3756681.jpg&fm=jpg');
    background-size: cover; /* Ensures the image covers the entire area */
    background-position: center; /* Centers the image */
    border-radius: 50%; /* Makes the div circular */
}



.pricing-card .pricing-header .price-circl {
    width: calc(33.3% - 30px);
    width: 120px;
    height: 120px;
    border-radius: 100%;
    left: calc(50% - 60px);
    top: 60%;
    background-color: white;
    position: absolute;
}
.basic .price-circl {
    border: 10px solid #000;
    transition: all 0.4s;
}
.basic:hover .price-circl {
    border-width: 5px;
  }
  .basic .buy-now:hover {
    background-image: none !important;
    background-color: #000 !important;
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
  }
.price-circl {
    width: 100px; /* Adjust dimensions as needed */
    height: 100px;
    background-image: url('https://i.pinimg.com/originals/61/60/b1/6160b11e04b2bf30070043223555b34e.jpg');
    background-size: cover; /* Ensures the image covers the entire area */
    background-position: center; /* Centers the image */
    border-radius: 50%; /* Makes the div circular */
}


.client-info {
    padding: 40px;
    text-align: start;
}
.client-info h5 {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.3px;
    opacity: 90%;
    text-align: center;
}
.client-card{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.client-info p {
    font-size: 14px;
    letter-spacing: 0.3px;
    text-align: justify;
}
.header-review {
    padding-top: 3px;
}

.header-review a.review {
    color: #fff;
    border: 1px solid #d59116;
    padding: 5px 30px;
    border-radius: 0px 0px 9px 10px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.3px;
    background: #d59116;
    cursor: pointer;
}
.tab div {
    display: none;
}

.tab div:target {
    display: block;
  
}