.slick-slider.slick-initialized button.slick-arrow.slick-prev:before {
   color: #ff8e00eb !important;
   font-size: 25px !important;
}
.slick-slider.slick-initialized button.slick-arrow.slick-next:before{
    color: #ff8e00eb !important;
    font-size: 25px !important;
}
.bright-card{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.img-bright {
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
}
.card-body {
    font-family: Poppins ,robot;
    letter-spacing: 0.3px;
    text-align: center;
    padding: 10px 30px 35px 30px;
}
.content{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: 0.5s;
    padding: 30px 0px 15px 0px;
}
.content:hover{
    transform: translateY(-33px) !important;
}

.card-body h5 {
    font-size: 19px;
    font-weight: 500;
    color: #faac4a;
    text-align: center;
}

.card-body p {
    font-size: 13px;
    margin-bottom: 25px;
}
.btn-setup {
    text-align: center;
}
a.more-btn {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 15px;
    font-family: poppins, robot;
    font-weight: 500;
    letter-spacing: 0.3px;
    border: 1px solid #faac4a;
    border-radius: 4px;
    padding: 4px 25px;
    color: black;
    transition: 0.6s;
}
a.more-btn:hover {
    color: #fff;
    background-color: #000;
    border: none;
    transition: 0.3s;
    font-weight: 400;
}
img.communication {
    width: 252px;
    height: 175px;
}

.swiper-product .icon svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 80px;
    transition: 0.7s;
    fill: #fff;
    width: 100px;
    height: 100px;
}

.swiper-product .card .face {
    /* width: 360px; */
    height: 265px;
    transition: 0.5s;
}

.swiper-product .card .face.face1 {
    position: relative;
    background: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transform: translateY(140px);
}

.swiper-product .card:hover .face.face1{
    background: #d59116;
    transform: translateY(0px);
}

.swiper-product .card .face.face1 .content {
    opacity: 1;
    transition: 0.5s;
}

.swiper-product .card:hover .face.face1 .content {
    opacity: 1;
}

.swiper-product .card .face.face1 .content i{
    max-width: 100px;
}

 .swiper-product .card .face.face2 {
    position: relative;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    /* box-shadow: 0 20px 50px rgba(0,0,0,0.8); */
    transform: translateY(-100px);
}
.card-our-pro{
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: none;
    border-radius: .25rem;
    margin: 5px 10px;
}
 .swiper-product .card:hover .face.face2{
    transform: translateY(0);
}

 .swiper-product .card .face.face2 .content p {
    margin: 0;
    padding: 0;
    color: #000;
    font-size: 14px;
    text-align: justify;
}

 .swiper-product .card .face.face2 .content h3 {
    margin: 0;
    padding: 0;
    color: #fff;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    letter-spacing: 0.3px;
    background: #d59116;
    padding: 10px 22px;
    border-radius: 10px 0px 10px 0px ;

    
} 

 .swiper-product a {
    text-decoration: none;
    color: #fff;
}
.swiper-product .content{
    padding: 15px 0px 15px 0px !important;
}
ul.benefits{
    margin: 0px;
    padding-left: 18px;
}
ul.benefits li {
    font-size: 14px;
    font-style: italic;
    color: green;
    text-align: justify;
}

.swiper-product .card .face.face1 .content h3 {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 100%);
    margin-top: 21px;
    font-size: 20px;
    text-transform: uppercase;
    width: 200px;
    text-align: center;
}






